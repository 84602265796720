import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from '../components/Layout'
import Seo from "../components/Seo"

const BlogArchivePost = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    // <Layout location={location} title={siteTitle} crumbLabel={post.frontmatter.title} >
    <Layout pageData={data.strapiBlog} >
      <Seo
        title={post.frontmatter.title}
        description={ post.excerpt }
      />

      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog-archive">Blog archief</Link></li>
          <li className="is-active">
            <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
          </li>
        </ul>
      </nav>

      <div className="container content">
        <h1 className="title">{post.frontmatter.title}</h1>

        {post.frontmatter.photos ? (
          <div className="columns is-multiline">
              {post.frontmatter.photos.map((image, i) => (
                <div className="column is-half" key={i}>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={image.name ? image.name : ""}
                    style={{ margin: '1rem 0' }}
                  />
                </div>
              ))}
          </div>
        ) : null}

        <div className="content" dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>

      <nav className="pagination is-centered" role="navigation" aria-label="pagination">
        {previous && (
          <Link to={previous.fields.slug} className="pagination-previous">
            {previous.frontmatter.title}
          </Link>
        )}
        {next && (
          <Link to={next.fields.slug} className="pagination-next">
            {next.frontmatter.title}
          </Link>
        )}
      </nav>

    </Layout>
  )
}
export default BlogArchivePost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    strapiBlog {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        photos {
          name
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
        tags
      }
    }
  }
`
